<template>
  <div
    v-cloak
    v-if="!this.bp"
    class="container p-t-1"
    style="
      background: rgb(2, 0, 36);
      background: linear-gradient(
        351deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(129, 10, 113, 1) 43%,
        rgba(87, 0, 255, 1) 70%
      );
    "
  >
    <div class="container-vista">
      <div class="info">
        <a href="https://cams.clubcamgirl.com"><img class="logoCamLogin m-b-7" src="/img/camclub.png" alt="" /></a>
        <div class="mobile-userimage  m-b-1">
          <div class="userimage m-b-3">
          <div class="back-white">
            <img v-if="this.pm" v-bind:src="this.model.profile_img" alt="" />
            <img v-else class="logouser" src="/img/logoupng.png" alt="" />
            <img
              v-if="this.model.isAprobed"
              class="verification"
              src="/img/verification.png"
              alt=""
            />
          </div>
          </div>
        </div>
        <span class="user-email"> @{{ this.model.username }} </span>
        <span class="user-link m-b-1">
          <a href=""> {{ this.model.website }} </a>
        </span>
        <span class="user-info m-b-3">
          {{ this.model.Bio || "" }}
        </span>
      </div>
      <div class="buttons">
        <button
          class="m-b-1 mobile size-1"
          v-bind:style="{ 'background-color': button.color }"
          v-for="button in this.Allbuttons"
          :key="button.uuid"
        >
          <a
            v-if="button.private === true"
            href="https://www.google.com"
            target="_blank"
          >
            <img class="block-img" src="/img/candado.png" alt="" />
            {{ button.title }}
          </a>
          <a v-bind:href="button.url" target="_blank">
            <img v-bind:src="button.logo" alt="" />
          </a>
        </button>
      </div>
    </div>
  </div>
  <div
    v-cloak
    v-else
    class="container p-t-1"
    v-bind:style="{ 'background-image': `url(${this.model.banner})`, 'background-repeat': 'no-repeat', 'background-size': 'cover' }"
  >
    <div class="container-vista">
      <div class="info">
        <img class="logoCamLogin" src="/img/camclub.png" alt="" />
        <div class="userimage m-b-2">
          <div class="back-white">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="user"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              class="svg-inline--fa fa-user fa-w-14 fa-5x"
              v-if="!this.pm"
            >
              <path
                fill="currentColor"
                d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"
                class=""
              ></path>
            </svg>
            <img v-if="this.pm" v-bind:src="this.model.profile_img" alt="" />
            <img
              v-if="this.model.isAprobed"
              class="verification"
              src="/img/verification.png"
              alt=""
            />
          </div>
        </div>
        <span class="user-email"> @{{ this.model.username }} </span>
        <span class="user-link m-b-2">
          <a href=""> https://mywebsite.com </a>
        </span>
        <span class="user-info m-b-2">
          {{ this.model.Bio || "" }}
        </span>
      </div>
      <div class="buttons">
        <button
          class="m-b-1 mobile size-1"
          v-bind:style="{ 'background-color': button.color }"
          v-for="button in this.Allbuttons"
          :key="button.uuid"
        >
          <a
            v-if="button.private === true"
            href="https://www.google.com"
            target="_blank"
          >
            <img class="block-img" src="/img/candado.png" alt="" />
            {{ button.title }}
          </a>
          <a v-bind:href="button.url" target="_blank">
            <img v-bind:src="button.logo" alt="" />
          </a>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Profile",
  metaInfo() {
    return {
      title: `@${this.username} on`,
      titleTemplate: "%s Club Cam Girls!",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
      link: [{ rel: "icon", href: "/favicon.png" }],
    };
  },
  data() {
    return {
      username: this.$route.params.username,
      model: Object,
      pm: false,
      bp: false,
      Allbuttons: [],
    };
  },
  mounted() {
    this.getModel();
  },
  methods: {
    async getModel() {
      const model = await this.axios({
        method: "get",
        baseURL: process.env.VUE_APP_BASE_URL,
        url: `/api/v1/models/user/${this.username}`,
      });
      if (model.data.status == 200) {
        const buttons = [...model.data.msg.buttons];
        for (let i in buttons) {
          if (buttons[i].url.length !== 0) {
            this.Allbuttons.push(buttons[i]);
          }
        }
        this.model = model.data.msg;
        if (
          this.model.profile_img != null &&
          this.model.profile_img.length > 0
        ) {
          this.pm = true;
        }

        if (this.model.banner !== null && this.model.banner.length > 0) {
          this.bp = true;
        }
      }

      if (model.data.status === 404) {
        this.$router.push("/NotFound");
      }
    },
  },
};
</script>

<style>
.container-portfolio {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(129, 10, 113, 1) 35%,
    rgba(87, 0, 255, 1) 75%
  );
  max-width: 100%;
}
.portfolio {
  padding: 6%;
  text-align: center;
}
.heading {
  background: #fff;
  padding: 1%;
  text-align: left;
  box-shadow: 0px 0px 4px 0px #545b62;
}
.heading img {
  width: 10%;
}
.bio-info {
  padding: 5%;
  background: #fff;
  box-shadow: 0px 0px 4px 0px #b0b3b7;
  opacity: 95%;
}
.name {
  font-family: "Charmonman", cursive;
  font-weight: 600;
}
.bio-image {
  text-align: center;
}
.bio-image img {
  border-radius: 55%;
  max-width: 200px;
}
.bio-content {
  text-align: left;
}
.bio-content p {
  font-weight: 600;
  font-size: 30px;
}
</style>