import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueNativeNotification from 'vue-native-notification'
import '@/assets/scss/styles.css'
import '@/assets/scss/additionals.css'
import "toastify-js/src/toastify.css"
import VueCookies from 'vue-cookies'
import  firebase from "firebase/app";
import VueMeta from 'vue-meta'

Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: "AIzaSyCKqtdOh5B_QXeQ-7dDhJDdHgxM9cyXjyE",
  authDomain: "vue-uploads.firebaseapp.com",
  projectId: "vue-uploads",
  storageBucket: "vue-uploads.appspot.com",
  messagingSenderId: "499311020910",
  appId: "1:499311020910:web:d6929470d3a47aae09cf7f"
};

firebase.initializeApp(firebaseConfig)

Vue.use(VueAxios, axios)
Vue.use(VueNativeNotification, {
  requestOnNotify: true
})
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
Vue.notification.requestPermission()
  .then(console.log)
Vue.use(VueCookies)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
